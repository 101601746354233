<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Organizaciones</h1>
		</div>
		<br />

		<!-- <DataGrid :config="dataGridConfig" :data="usuarios" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones"> -->
		<DataGrid :config="dataGridConfig" :data="organizaciones" :select="seleccionar" @actions="dataGridActions">
			<div class="col-sm-2">
				<button class="btn warning-btn" @click="obtener_organizaciones">Recargar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','crear_organizacion')" class="btn principal-btn" @click="modal=1">Nuevo</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','editar_organizacion')" class="btn secondary-btn" @click="editar">Editar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','eliminar_organizacion')" class="btn danger-btn" @click="pre_eliminar">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="modal!=0" @close="cancelar" class="bpb-modal">
			<form @submit="guardar">
				<h2 class="title">Organización</h2>
				<div class="body">
					<div class="row form-group">
						<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-10"><input v-model="organizacion.nombre" class="form-control" type="text" name="nombre" id="nombre" autocomplete="off"></div>
					</div>
					<div class="row form-group">
						<label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-10"><input v-model="organizacion.descripcion" class="form-control" type="text" name="descripcion" id="descripcion" autocomplete="off"></div>
					</div>
					<div class="row form-group">
						<label for="estatus" class="col-form-label col-sm-2">Estatus</label>
						<div class="col-sm-10">
							<select v-model="organizacion.estatus" name="estatus" id="estatus" class="form-control">
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</select>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-12 d-flex justify-content-end">
							<div class="col-sm-3">
								<input class="btn principal-btn" type="submit" value="Guardar" /> 
							</div>
							<div class="col-sm-3">
								<button class="btn complementary-btn" @click="cancelar">Cancelar</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="eliminar" @close="cancelar_eliminar" :options="{width: '30vw', type:'error'}" class="bpb-modal">
			<h2 class="title">Eliminar organizacion(es)</h2>
			<div class="body">
				<p class="text-center">¿Realmente eliminarás las organizaciones seleccionadas?</p>
			</div>
			<div class="footer">
				<div class="d-flex justify-content-end">
					<div class="col-sm-3">
						<button class="btn principal-btn" @click="eliminar_organizaciones">Eliminar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn complementary-btn" @click="cancelar_eliminar">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'

	import api from '@/apps/account/api/account'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'organizaciones'
					,cols: {
						id: 'ID'
						,nombre: 'Nombre'
						,descripcion: 'Descripción'
						,estatus: 'Estatus'
					}
					,filters: {
						cols: {
							id: 'ID'
							,nombre: 'Nombre'
							,descripcion: 'Descripción'
							,estatus: {
								text: 'Estatus'
								,options: {
									1: 'Activo'
									,0: 'Inactivo'
								}
							}
							,created_at: 'Fecha de creación'
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,organizaciones: []
				,organizacion: {
					nombre: null
					,descripcion: null
					,estatus: 1
				}
				,modal: 0
				,seleccionar: false
				,seleccion: []
				,eliminar: false
			}
		}
		,mounted: function() {
			this.obtener_organizaciones();
		}
		,methods: {
			obtener_organizaciones: async function() {
				let response = (await api.listar_organizaciones(this.options)).data;

				this.organizaciones = response.data;

				this.dataGridConfig.paginator.pagina_actual = response.current_page;
				this.dataGridConfig.paginator.total_registros = response.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(response.per_page);
			}
			,cancelar: function(e) {
				if (e) e.preventDefault();

				this.modal = 0;
				this.organizacion = {
					nombre: null
					,descripcion: null
					,estatus: 1
				}
				,this.seleccionar = false;
			}
			,dataGridActions: function(tipo, valor) {
				if (tipo == 'options') {
					this.options = valor;
					this.obtener_organizaciones();
				}else {
					this.seleccion = valor;
					this.seleccionar = true;
				}
			}
			,editar: function() {
				if (this.seleccion.length > 0) {
					this.organizacion = this.seleccion[0];
					this.$log.info('organizacion', this.organizacion);
					this.modal = 2;
				}else
					this.$helper.showMessage('Error','Tienes que seleccionar una organización a editar', 'error', 'alert');
			}
			,guardar: async function(e) {
				e.preventDefault();

				if (this.modal == 1)
					await api.crear_organizacion(this.organizacion);
				else
					await api.editar_organizacion(this.organizacion.id, this.organizacion);

					this.cancelar();
					this.obtener_organizaciones();
			}
			,pre_eliminar: function() {
				if (this.seleccion.length > 0)
					this.eliminar = true;
				else
					this.$helper.showMessage('Error','Tienes que seleccionar al menos una organización a eliminar', 'error', 'alert');
			}
			,cancelar_eliminar: function() {
				this.seleccionar = false;
				this.eliminar = false;
			}
			,eliminar_organizaciones: async function() {
				await api.eliminar_organizacion(this.seleccion[0].id);
				this.cancelar_eliminar();
				this.obtener_organizaciones();
			}
		}
	}
</script>